<template>
  <div class="background-uploader">
    <div class="label">Обложка</div>
    <div v-if="$refs.uploadConstructor && $refs.uploadConstructor.dropActive" class="drop-active">
      Положите файл сюда
    </div>
    <div class="files-background">
      <file-upload
        accept=".jpg, .jpeg, .png"
        input-id="uploadConstructor"
        class="file-uploader"
        post-action="/upload/post"
        :multiple="multiple"
        :drop="drop"
        :drop-directory="dropDirectory"
        v-model="file"
        ref="uploadConstructor"
        v-if="!cover"
      >
        <div class="file-description">
          <i class="icon-download"></i>
          <span>Перетащите сюда файл или</span>
          <div>Загрузить</div>
        </div>
      </file-upload>
      <div class="cover" v-if="cover">
        <img :src="cover" alt="cover" />
        <div class="delete" @click="clearPool">Удалить</div>
      </div>
    </div>
  </div>
</template>

<script>
const FileUpload = () => import("vue-upload-component");
import { domainStorage } from "@/globalVariables";

export default {
  name: "FileUploaderCover",
  mixins: [],
  props: [
    "multiple",
    "drop",
    "dropDirectory",
    "fileDrop",
    "deep",
    "lengthText",
    "showOnlyFiles",
    "editValue"
  ],
  components: { FileUpload },
  data() {
    return {
      file: [],
      cover: null
    };
  },
  mounted() {
    if (this.editValue) {
      this.cover = domainStorage + this.editValue;
    }
  },
  methods: {
    getUrlFile() {
      let reader = new FileReader();
      reader.readAsDataURL(this.file[0].file);
      reader.onload = () => {
        this.cover = reader.result;
      };
      this.$emit("changeCover", this.file);
    },
    clearPool() {
      this.cover = null;
      this.$emit("clearParentPool");
    }
  },
  computed: {},
  watch: {
    file() {
      this.getUrlFile();
    }
  },
  validations: {},
  directives: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/breakpoints/breakpoints.scss";
.background-uploader {
  width: 100%;
  position: relative;
  .label {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: $color-text-secondary;
  }
  .drop-active {
    width: 100%;
    height: 76px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    border: 1px dashed $color-contour;
    border-radius: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    z-index: 100;
    color: $color-interactive;
    background-color: white;
  }
  .files-background {
    .file-uploader {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 76px;
      border: 1px dashed $color-contour;
      border-radius: 10px;
      /deep/ {
        .file-description {
          display: flex;
          align-items: center;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 13px;
          color: $color-text-primary-2;

          .icon-download {
            margin-right: 12px;
            color: $color-interactive;
            font-size: 20px;
          }
          div {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 144px;
            height: 32px;
            border: 1px solid $color-interactive;
            border-radius: 4px;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: $color-interactive;
            margin-left: 12px;
          }
          @media screen and (max-width: $lg) {
            span,
            i {
              display: none;
            }
          }
        }
        label {
          cursor: pointer;
        }
      }
    }
    .cover {
      .name {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: $color-text-accent;
      }
      img {
        width: 200px;
        height: 200px;
        object-fit: cover;
        border-radius: 5px;
      }
      .delete {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: $color-charts-red-1;
        user-select: none;
        cursor: pointer;
      }
    }
  }
}
</style>
